let debounceFunc = null;
async function inDelay(model, callback) {
    await callback(model);
}
async function debounceIt(timeOut, callback, model = null) {
    clearTimeout(this.debounceFunc);
    this.debounceFunc = setTimeout(() => {
        return inDelay(model, callback);
    }, timeOut);
}
export default { debounceIt }