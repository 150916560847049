<template>
  <div class="animated">
    <CustomModal
      :modalTitle="$t('delete')"
      :button1Title="$t('delete')"
      button1Class="btn btn-danger"
      :show="removeModal"
      @hide="removeModal = false"
      @onButton1Click="remove()"
    >
      {{ $t("deleteConfirm") }}
    </CustomModal>
    <b-card no-body>
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("newsComments") }}
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col sm="6">
            <DateBetweenPicker
              :from="filters.fromCreatedAt"
              :to="filters.toCreatedAt"
              @change="dateChanged"
            ></DateBetweenPicker>
          </b-col>
          <b-col sm="2">
            <b-form-group>
              <label for="approve">{{ $t("approveStatus") }}</label>
              <BooleanStateDropDown
                :placeholder="$t('approveStatus')"
                :state="filters.approved"
                :includeall="true"
                @change="(val) => (filters.approved = val.id)"
              ></BooleanStateDropDown>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group>
              <label for="newsId">{{ $t("newsId") }}</label>
              <NormalTextBox
                prename="comments"
                style="margin-top: -1rem"
                id="newsId"
                :withLabel="false"
                icon="fa fa-newspaper-o"
                v-model="newsId"
                :value="newsId"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <i class="fa fa-filter mr-1"></i>
        {{ $t("filter") }}
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col sm="12">
            <v-server-table
              :columns="columns"
              ref="table"
              :options="options"
              id="dataTable"
              class="dataGrid gridDetail gridDetail"
              v-if="$auth.hasAuth($options, 'list')"
            >
              <template slot="approved" slot-scope="props">
                <b-badge
                  size="lg"
                  :variant="props.row.approved ? 'success' : 'danger'"
                  >{{ props.row.approved ? $t("yes") : $t("no") }}</b-badge
                >
              </template>
              <template slot="totalLikeCount" slot-scope="props">
                <b-badge size="lg" variant="success">{{
                  props.row.totalLikeCount
                }}</b-badge>
              </template>
              <template slot="creator" slot-scope="props">
                {{ props.row.user.userName }}
              </template>
              <template slot="newsTitle" slot-scope="props">
                <div :title="props.row.news.shortDescription">
                  {{ props.row.news.title }}
                </div>
              </template>
              <template slot="buttons" slot-scope="props">
                <div class="action-buttons nowrap">
                  <b-button
                    variant="danger icon"
                    v-b-tooltip.hover
                    :title="$t('delete')"
                    class="mr-1 btn-brand"
                    v-if="$auth.hasAuth($options, 'remove')"
                  >
                    <i
                      class="fa fa-trash"
                      @click="
                        () => {
                          removeModal = true;
                          selectedId = props.row.id;
                        }
                      "
                    ></i>
                  </b-button>
                  <b-button
                    v-if="
                      !props.row.approved && $auth.hasAuth($options, 'approve')
                    "
                    variant="success icon"
                    v-b-tooltip.hover
                    :title="$t('approve')"
                    class="mr-1 btn-brand"
                  >
                    <i
                      class="fa fa-check-square"
                      @click="approve(props.row.id)"
                    ></i>
                  </b-button>
                  <b-button
                    v-if="
                      props.row.approved && $auth.hasAuth($options, 'unapprove')
                    "
                    variant="secondary icon"
                    v-b-tooltip.hover
                    :title="$t('unApprove')"
                    class="mr-1 btn-brand"
                  >
                    <i
                      class="fa fa-minus-square"
                      @click="unapprove(props.row.id)"
                    ></i>
                  </b-button>
                </div>
              </template>
              <div
                slot="child_row"
                slot-scope="props"
                class="text-justify p-4"
                v-html="props.row.content"
              ></div>
            </v-server-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import gridOptions from "../../shared/grid-options";
import moment from "moment";
import http from "../../shared/http";
import { ServerTable } from "vue-tables-2";
import dateHelper from "../../shared/date-helper";
import utility from "../../shared/utils/screen-helper";
import toast from "../../shared/toast-helper";
import CustomModal from "../../widgets/form/CustomModal";
import DateBetweenPicker from "../../widgets/form/DateBetweenPicker";
import BooleanStateDropDown from "../../widgets/BooleanStateDropDown";
import NormalTextBox from "../../widgets/form/NormalTextBox";
import toastHelper from "../../shared/toast-helper";
import debounceGenerator from "../../shared/utils/debounce-generator";
export default {
  name: "CommentsList",
  components: {
    ServerTable,
    CustomModal,
    DateBetweenPicker,
    BooleanStateDropDown,
    NormalTextBox,
  },
  methods: {
    requeryNewsId() {
      this.filters.newsId = this.newsId;
    },
    remove() {
      this.removeModal = false;
      http
        .post(`NewsComments/delete?newsCommentId=${this.selectedId}`)
        .then((response) => {
          toastHelper.success(response.message);
          this.getList();
        });
    },
    changeState(commentId, state) {
      const model = {
        id: commentId,
        approved: state,
      };
      http.post(`NewsComments/changeapprovedstatus`, model).then((response) => {
        toastHelper.success(response.message);
        this.getList();
      });
    },
    approve(commentId) {
      this.changeState(commentId, true);
    },
    unapprove(commentId) {
      this.changeState(commentId, false);
    },
    getList() {
      this.$refs.table.setPage(1);
    },
    getTime(val) {
      if (val.indexOf(".") >= 0) {
        return val.substr(0, val.indexOf("."));
      } else {
        return val;
      }
    },
    dateChanged(dates) {
      this.filters.fromCreatedAt = dates?.from ?? null;
      this.filters.toCreatedAt = dates?.to ?? null;
    },
  },
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    newsId() {
      debounceGenerator.debounceIt(800, this.requeryNewsId);
    },
  },
  data() {
    return {
      removeModal: false,
      selectedId: 0,
      newsId: this.$route.params.id ? this.$route.params.id : null,
      filters: {
        fromCreatedAt: null,
        toCreatedAt: null,
        approved: null,
        newsId: this.$route.params.id ? this.$route.params.id : null,
      },
      columns: [
        "newsTitle",
        "title",
        "totalLikeCount",
        "approved",
        "createdAt",
        "creator",
        "buttons",
      ],
      options: {
        ...gridOptions.getAll(),
        pagination: { chunk: 10 },
        perPageValues: [10, 25, 50, 100],
        requestFunction: (data) => {
          let path = "NewsComments/getlistbypaging";
          if (!data.orderBy) {
            data.orderBy = "createdAt";
            data.ascending = 0;
          }
          if (this.filters) {
            data.fromCreatedAt = this.filters.fromCreatedAt;
            data.toCreatedAt = this.filters.toCreatedAt;
            data.approved = this.filters.approved;
            data.newsId = this.filters.newsId;
          }

          return http
            .get(path, { params: data })
            .catch(function (e) {
              this.dispatch("error", e);
            })
            .finally(() => {
              utility.scrollToTop();
            });
        },
        sortable: "title,content,totalLikeCount,approved,creator",
        headings: {
          title: this.$t("title"),
          newsTitle: this.$t("newsTitle"),
          totalLikeCount: this.$t("totalLikeCount"),
          approved: this.$t("approved"),
          createdAt: this.$t("createdAt"),
          creator: this.$t("author"),
          buttons: this.$t("operations"),
        },
        templates: {
          createdAt: (h, row) =>
            moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
      },
    };
  },
};
</script>
<style scoped>
td {
  padding: 0px !important;
}
</style>